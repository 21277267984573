<template>
  <v-container
      id="extended-tables"
      fluid
      tag="section"
  >
    <v-row no-gutters>
      <v-col cols="12" md="12">

        <data-grid
            :envSelected="envSelected"
            :grid="grid"
            @doAction="gridAction"
            @paginate="listItems"
        >

          <template v-slot:column-name="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span
                    v-bind="attrs"
                    v-on="on"
                    class="mr-1 font-weight-medium">{{ item.name }}</span>
                <v-chip
                    v-bind="attrs"
                    v-on="on"
                    small
                    label>{{ item.code }}
                </v-chip>
              </template>
              <span>
                Caching set to: {{ item._TTL }}
              </span>
            </v-tooltip>
          </template>

          <template v-slot:column-description="{ item }">
            {{ item.description }}
          </template>

          <template v-slot:column-ts="{ item }">
            <div v-if="item.ts">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs"
                      v-on="on"
                      class="">{{ new Date(item.ts).toDateString() }}
                  </span>
                </template>
                <span>
                {{ new Date(item.ts).toDateString() }} @ {{ new Date(item.ts).toLocaleTimeString() }}
              </span>
              </v-tooltip>
            </div>


          </template>

          <!--          <template v-slot:column-_TTL="{ item }">-->
          <!--            {{ new Date( item._TTL ).toDateString() }} @ {{ new Date( item._TTL ).toLocaleTimeString() }}-->
          <!--          </template>-->

          <template v-slot:column-expanded="{ headers, item }">
            <td :colspan="headers.length" v-if="grid.fieldAccess.config">
              <template v-if="tenantType==='general'">
                <json-viewer
                    class="my-4"
                    :value="item.config"
                    :expand-depth="4"
                    theme="my-awesome-json-theme"
                    copyable
                    boxed
                    sort
                ></json-viewer>
              </template>
            </td>

          </template>
        </data-grid>

      </v-col>
    </v-row>

    <clone-dialog
        :envSelected="envSelected"
        :options="cloneDialog"
        :doAction="doClone"
    ></clone-dialog>

    <share-dialog
        :envSelected="envSelected"
        :options="shareDialog"
        :doAction="doShare"
    >
    </share-dialog>

    <custom-dialog :dialog="keyDialog" :env-selected="envSelected" :do-action="updateTenantAppKey">
      <v-row v-if="keyDialog.data">
        <v-col cols="12">
          <v-menu
              v-model="keyDialog.menu2"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto">

            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  v-model="keyDialog.data.expDate"
                  label="Select new Expiry Date"
                  prepend-icon="mdi-calendar"
                  hint="Provide the key expiry date or leave it blank if the key should never expire"
                  readonly
                  v-bind="attrs"
                  v-on="on"
              ></v-text-field>
            </template>

            <v-date-picker
                v-model="keyDialog.data.expDate"
                @input="keyDialog.menu2 = false">

            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12">
          <v-slide-y-transition>
            <v-textarea
                auto-grow
                rows="3"
                row-height="15"
                v-show="!keyDialog.data.new"
                v-model="keyDialog.data.public"
                label="Public Key"
                prepend-icon="mdi-key"
                readonly
            ></v-textarea>
          </v-slide-y-transition>
          <v-switch
              inset
              v-model="keyDialog.data.new"
              label="Regenerate New Key"
          ></v-switch>

        </v-col>
      </v-row>
    </custom-dialog>

    <custom-dialog :dialog="securityDialog" :env-selected="envSelected" :do-action="updateAppSecurity">
      <v-row v-if="securityDialog.data">
        <v-col cols="12">

          <v-tabs right>
            <v-tab>Network</v-tab>
            <v-tab>Device</v-tab>

            <v-tab-item class="pt-4">
              <v-card-text>
                <template v-for="(geoOptions, proto) in securityDialog.data.geo">
                  <v-combobox
                      v-if="proto !== 'search'"
                      :key="proto"
                      v-model="securityDialog.data.geo[proto]"
                      :items="geoOptions"
                      :search-input.sync="securityDialog.data.geo.search"
                      hide-selected
                      clearable
                      :label="proto"
                      :hint="`Enter the IP address or CIDR that you wish to ${proto} access to this application.`"
                      multiple
                      persistent-hint
                      small-chips
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            No results matching "<strong>{{ securityDialog.data.geo.search }}</strong>". Press
                            <kbd>enter</kbd> to create a new one
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-combobox>
                </template>
              </v-card-text>
            </v-tab-item>
            <v-tab-item class="pt-4">
              <template v-for="(deviceOptions, proto) in securityDialog.data.device">
                <div :key="proto">
                  <v-card-subtitle class="ml-0 pl-4 font-italic">{{ proto }} device access to this application.
                  </v-card-subtitle>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12">
                        <v-btn
                            @click="securityDialog.data.device[proto].push({family: '', minor: 0, major: 0, patch: {min: 0, max: 0}});"
                            x-small
                            fab
                            color="secondary">
                          <v-icon small>mdi-plus</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-row v-for="(allowDevice, i) in deviceOptions" :key="i">
                      <v-col cols="3">
                        <v-text-field label="Family" v-model="allowDevice.family"></v-text-field>
                      </v-col>
                      <v-col cols="2">
                        <v-text-field type="number" v-model="allowDevice.minor" min="0"
                                      label="Min Version"></v-text-field>
                      </v-col>
                      <v-col cols="2">
                        <v-text-field type="number" v-model="allowDevice.major" min="0"
                                      label="Max Version"></v-text-field>
                      </v-col>
                      <v-col cols="2">
                        <v-text-field type="number" v-model="allowDevice.patch.min" min="0"
                                      label="Min Patch number"></v-text-field>
                      </v-col>
                      <v-col cols="2">
                        <v-text-field type="number" v-model="allowDevice.patch.max" min="0"
                                      label="Max Patch number"></v-text-field>
                      </v-col>
                      <v-col cols="1">
                        <v-btn
                            @click="deviceOptions.splice(i, 1);"
                            x-small
                            fab
                            class="mt-5"
                            color="error">
                          <v-icon small>mdi-delete</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-divider class="mx-1 mt-1"></v-divider>
                </div>
              </template>
            </v-tab-item>
          </v-tabs>
        </v-col>
      </v-row>
    </custom-dialog>

    <custom-dialog :dialog="socialDialog" :env-selected="envSelected" :do-action="updateSocialSettings">
      <v-skeleton-loader
          v-if="socialDialog.loading"
          type="article, actions"
      ></v-skeleton-loader>
      <template v-else>
        <v-row v-if="socialDialog.data">
          <v-col cols="12" v-if="socialDialog.data.type === 'oauth2'">
            <v-card>
              <v-card-title class="pl-5 ml-0 text-left">
                <h3>Server Settings</h3>
              </v-card-title>
              <v-card-text>
                <v-text-field
                    v-model="socialDialog.data.client.id"
                    label="Client ID"
                    prepend-icon="mdi-account-network"
                    hint="Provide the platform unique application id"
                ></v-text-field>
                <v-text-field
                    v-model="socialDialog.data.client.secret"
                    label="Client Secret"
                    type="password"
                    prepend-icon="mdi-key"
                    hint="Provide the platform unique application secret"
                ></v-text-field>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" v-if="socialDialog.data.type === 'ldap'">
            <v-card class="mb-5">
              <v-card-title class="pl-5 ml-0 text-left">
                <h3>Server Settings</h3>
              </v-card-title>
              <v-card-text>
                <v-text-field
                    v-model="socialDialog.data.client.url"
                    label="LDAP Server Address(es)"
                    prepend-icon="mdi-server"
                    hint="Provide the list of LDAP server URL(s) separated by a comma (,)"
                ></v-text-field>
                <v-row>
                  <v-col cols="6" xs="12" md="6">
                    <v-text-field
                        v-model="socialDialog.data.client.timeout"
                        label="Timeout"
                        hint="Provide the time to wait for the LDAP server(s) to reply before giving up in milliseconds."
                        type="number"
                        prepend-icon="mdi-av-timer"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" xs="12" md="6">
                    <v-text-field
                        v-model="socialDialog.data.client.connectTimeout"
                        label="Connection Timeout"
                        hint="Provide the connection timeout to the LDAP server(s) in milliseconds."
                        type="number"
                        prepend-icon="mdi-av-timer"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-switch
                    inset
                    v-model="socialDialog.data.client.reconnect"
                    label="Auto Reconnect"
                >
                </v-switch>
              </v-card-text>
            </v-card>
            <v-card>
              <v-card-title class="pl-5 ml-0 text-left">
                <h3>LDAP Search Query</h3>
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="6" xs="12" md="6">
                    <v-text-field
                        v-model="socialDialog.data.scope"
                        label="Scope"
                        prepend-icon="mdi-magnify"
                        hint="Provide the LDAP search scope"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" xs="12" md="6">
                    <v-text-field
                        v-model="socialDialog.data.base"
                        label="Base"
                        prepend-icon="mdi-folder"
                        hint="Provide the LDAP search base"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="3" xs="12" md="3">
                    <v-text-field
                        v-model="socialDialog.data.user.key"
                        label="User Key ID"
                        hint="Provide the LDAP unique user key ID Field"
                        prepend-icon="mdi-account-outline"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3" xs="12" md="3">
                    <v-text-field
                        v-model="socialDialog.data.user.fields.firstName"
                        label="User First Name Field"
                        hint="Provide the LDAP unique user Field to read the user's given name from"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3" xs="12" md="3">
                    <v-text-field
                        v-model="socialDialog.data.user.fields.lastName"
                        label="User Last Name Field"
                        hint="Provide the LDAP unique user Field to read the user's family name from"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3" xs="12" md="3">
                    <v-text-field
                        v-model="socialDialog.data.user.fields.email"
                        label="User E-mail Fields"
                        hint="Provide the LDAP unique user Field to read the user's email address from"
                        prepend-icon="mdi-email"
                    ></v-text-field>
                  </v-col>
                </v-row>

              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12">
            <v-card>
              <v-card-title class="pl-5 ml-0 text-left">
                <h3>Attach ARW</h3>
              </v-card-title>
              <v-card-text>
                <v-select
                    v-model="socialDialog.data.register"
                    label="ARW"
                    prepend-icon="mdi-robot"
                    hint="Attach a custom ARW flow on users who register with this platform."
                    :items="socialDialog.arwList"
                ></v-select>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </template>

    </custom-dialog>
  </v-container>
</template>

<script>

import globalMixins from "../../../../mixins/globalMixins";
import fieldsMixins from "./components/fieldAccess";

import dataGrid from "@/views/internal/components/custom/dataGrid";
import CloneDialog from "@/views/internal/components/custom/cloneDialog";
import ShareDialog from "@/views/internal/components/custom/shareDialog";
import CustomDialog from "@/views/internal/components/custom/dialog";
import JsonViewer from 'vue-json-viewer';

export default {
  name: 'TenantsApps',
  props: {
    environments: {
      type: Array
    },
    envSelected: {
      type: Object
    },
    tenantType: {
      type: String
    }
  },

  data: () => {
    let data = {
      grid: {
        fieldAccess: {},
        //mandatory
        headers: [
          // {
          //   text: '',
          //   value: 'data-table-expand'
          // },
          {
            text: 'Name',
            value: 'name'
          },
          {
            text: 'Description',
            value: 'description',
            //width: '40%'
          },
          {
            text: 'Last Modified',
            value: 'ts',
          },
          // {
          //   text: 'Caching TTL',
          //   value: '_TTL',
          // },
          {
            align: 'right',
            text: 'Actions',
            value: 'actions',
            width: '31%'
          },
        ],
        items: [],
        selected: [],
        //optional
        loading: true,
        //optional
        columns: [
          {
            name: 'item.name'
          },
          {
            name: 'item.description'
          },
          {
            name: 'item.ts'
          },
          // {
          //   name: 'item._TTL'
          // },
          {
            name: 'item.expanded'
          }
        ],
        //optional
        search: {
          keyword: ''
        },
        //optional
        itemID: 'id',
        //optional
        multi: [
          {
            icon: 'delete',
            label: 'Delete',
            color: 'error',
            acl: {method: 'delete', route: `/environments/:env/tenants/:id`},
            method: 'deleteItem',
            //optional
            confirm: `Are you sure you want to delete these Application(s)?`
          }
        ],
        //optional
        sortingList: ['name', 'description'],
        sort: ['name', 1, 'description', 1],
        //optional
        headActions: [
          {
            icon: 'refresh',
            label: '',
            color: 'purple',
            acl: {method: 'get', route: `/environments/:env/:type/tenants`},
            method: 'listItems'
          },
          {
            icon: 'plus',
            label: 'create',
            color: 'success',
            acl: {method: 'put', route: `/environments/:env/tenants`},
            method: 'addItem'
          }
        ],
        //optional
        rowActions: [
          {
            method: 'showItemKey',
            color: 'info',
            icon: 'mdi-key',
            allow: {
              method: 'patch',
              route: '/environments/:env/tenants/:id/key'
            },
            tooltip: "Key Security"
          },
          {
            method: 'showAppSecurity',
            color: 'orange',
            icon: 'mdi-shield',
            allow: {
              method: 'patch',
              route: '/environments/:env/tenants/:id/security'
            },
            tooltip: "Network & Device Security"
          },
          {
            method: 'cloneItem',
            color: 'secondary',
            icon: 'mdi-content-copy',
            allow: {
              method: 'post',
              route: '/environments/:env/tenants/:id/copy'
            },
            tooltip: "Copy"
          },
          {
            method: 'shareItem',
            color: 'primary',
            icon: 'mdi-share-variant',
            allow: {
              method: 'post',
              route: '/environments/:env/tenants/:id/share'
            },
            tooltip: "Share"
          },
          {
            method: 'editItem',
            color: 'success',
            icon: 'mdi-pencil',
            allow: {
              method: 'patch',
              route: '/environments/:env/tenants/:id'
            },
            tooltip: "Edit"
          },
          {
            method: 'deleteItem',
            color: 'error',
            icon: 'mdi-delete',
            allow: {
              method: 'delete',
              route: '/environments/:env/tenants/:id'
            },
            tooltip: "Delete"
            // optional
            // confirm: `Are you sure you want to delete this Custom Setting?`
          },
        ]
      },
      cloneDialog: {
        trigger: false,
        original: {},
        actions: [
          {
            color: 'success',
            icon: 'content-copy',
            label: 'Copy',
            method: 'doClone',
            allow: {
              method: 'post',
              route: '/environments/:env/tenants/:id/copy'
            }
          }
        ]
      },
      shareDialog: {
        trigger: false,
        original: {},
        list: [],
        actions: [
          {
            color: 'success',
            icon: 'share-variant',
            label: 'Share',
            method: 'doShare',
            allow: {
              method: 'post',
              route: '/environments/:env/tenants/:id/share'
            }
          }
        ]
      },
      keyDialog: {
        title: '',
        trigger: false,
        data: null,
        actions: [
          {
            method: 'updateTenantAppKey',
            icon: 'content-save',
            color: 'success',
            label: 'Change',
            allow: {
              method: 'patch',
              route: '/environments/:env/tenants/:id/key'
            }
          }
        ]
      },
      securityDialog: {
        title: '',
        trigger: false,
        data: null,
        actions: [
          {
            method: 'updateAppSecurity',
            icon: 'content-save',
            color: 'success',
            label: 'Update Security',
            allow: {
              method: 'patch',
              route: '/environments/:env/tenants/:id/security'
            }
          }
        ]
      },
      socialDialog: {
        title: '',
        trigger: false,
        loading: true,
        data: null,
        actions: [
          {
            method: 'updateSocialSettings',
            icon: 'content-save',
            color: 'success',
            label: 'Save Changes',
            allow: {
              method: 'patch',
              route: '/environments/:env/tenants/:id/social'
            }
          }
        ]
      }
    };

    return data;
  },

  components: {
    dataGrid,
    JsonViewer,
    CloneDialog,
    ShareDialog,
    CustomDialog
  },

  mixins: [globalMixins, fieldsMixins],

  async created() {
    this.grid.fieldAccess = this.tenantListAccess();

    if (this.tenantType === 'social') {
      this.grid.rowActions.unshift({
        method: 'showSocial',
        color: 'success',
        icon: 'mdi-account-network',
        allow: {
          method: 'patch',
          route: '/environments/:env/tenants/:id/social'
        },
        tooltip: "Login 3rd Party Settings"
      });

    } else {
      this.grid.expand = {
        single: false,
        items: []
      };
    }

    setTimeout(() => {
      this.listItems();
    }, 2000);

  },

  methods: {

    //list
    async listItems(page, limit, sort) {
      const _self = this;

      this.grid.loading = true;
      this.grid.items = [];

      if (page !== undefined) {
        this.grid.page = page;
      }
      if (limit !== undefined) {
        this.grid.limit = limit;
      }
      if (sort !== undefined) {
        this.grid.sort = sort;
      }

      const apiOptions = {
        noLoading: true,
        url: `/consoleapi/environments/${this.envSelected.value}/${this.tenantType}/tenants`,
        method: "get",
        params: {
          offset: (this.grid.page !== undefined) ? this.grid.page : 0,
          limit: (this.grid.limit !== undefined) ? this.grid.limit : 10,
          sort: this.grid.sort
        }
      };

      function renderCacheTTL(record) {
        for (let i = 0; i < _self.msCacheOptions.length; i++) {
          if (_self.msCacheOptions[i].value === record._TTL) {
            record._TTL = _self.msCacheOptions[i].text;
          }
        }

      }

      this.getSendData(apiOptions).then((response) => {
        this.grid.items = response.items || [];
        this.grid.loading = false;

        if (this.grid.items.length > 0) {
          this.grid.items.forEach((oneTenant) => {
            renderCacheTTL(oneTenant);
          });
        }
      });
    },

    //grid action hook
    gridAction(action, item, extra) {
      if (item) {
        this[action](item, extra);
      } else {
        this[action](extra);
      }
    },

    //add
    addItem() {
      this.goToPage({route: 'createTenant', params: {envCode: this.envSelected.value}});
    },

    //edit
    editItem(item) {
      this.goToPage({route: 'editTenantConfig', params: {id: item.id, envCode: this.envSelected.value}});
    },

    //delete
    deleteItem(item, multi) {
      const _self = this;
      if (multi || (!multi && confirm(`Are you sure you want to remove application: ${item.name}`))) {
        doDelete();
      }

      function doDelete() {
        const apiOptions = {
          url: `/consoleapi/environments/${_self.envSelected.value}/tenants/${item.id}`,
          method: "delete"
        };
        _self.getSendData(apiOptions).then(() => {
          _self.pushMessage({
            type: 'success',
            title: `Item Deleted`,
            text: `This application has been deleted.`
          });
          setTimeout(() => {
            _self.listItems();
          }, 2000);
        });
      }
    },

    //clone
    cloneItem(item) {
      this.cloneDialog.original = {...item};
      this.cloneDialog.trigger = true;
    },

    doClone(value, item) {
      const self = this;
      const apiOptions = {
        url: `/consoleapi/environments/${self.envSelected.value}/tenants/${item.id}/copy`,
        method: "post",
        params: {name: value}
      };
      this.cloneDialog.trigger = false;
      self.getSendData(apiOptions).then(() => {
        self.pushMessage({
          type: 'success',
          title: `Item Copied`,
          text: `Application has been copied to ${value}.`
        });
        setTimeout(() => {
          self.listItems();
        }, 2000);
      });
    },

    //share
    shareItem(item) {
      this.shareDialog.original = {...item};
      this.shareDialog.trigger = true;
      this.shareDialog.list = [...this.environments];
      for (let i = this.shareDialog.list.length - 1; i >= 0; i--) {
        if (item.env === this.shareDialog.list[i].code.toUpperCase()) {
          this.shareDialog.list.splice(i, 1);
        }
      }
    },

    doShare(value, item) {
      const self = this;
      const apiOptions = {
        url: `/consoleapi/environments/${self.envSelected.value}/tenants/${item.id}/share`,
        method: "post",
        params: {environments: value}
      };
      this.shareDialog.trigger = false;
      self.getSendData(apiOptions).then(() => {
        self.pushMessage({
          type: 'success',
          title: `Item Shared`,
          text: `Application has been shared with environments: ${value.join(' - ')}.`
        });
        setTimeout(() => {
          self.listItems();
        }, 2000);
      });
    },

    //key update
    showItemKey(item) {
      this.keyDialog.width = 800;
      this.keyDialog.title = 'Key Security for ' + item.code;
      this.keyDialog.data = {...item.key};

      //TODO: when the dialog opens and if the expDate is set,
      // we're getting an error when trying to change the value
      if (this.keyDialog.data.expDate) {
        this.keyDialog.data.expDate = new Date(this.keyDialog.data.expDate);

        let expDate = null;

        if (this.keyDialog.data.expDate) {
          expDate = new Date(this.keyDialog.data.expDate).getFullYear();
          expDate += '-';
          let m = new Date(this.keyDialog.data.expDate).getMonth() + 1;
          expDate += (m < 10) ? `0${m}` : m;
          expDate += '-';
          let d = new Date(this.keyDialog.data.expDate).getDate();
          expDate += (d < 10) ? `0${d}` : d;

          this.keyDialog.data.expDate = expDate;
        }

      }
      this.keyDialog.trigger = true;
      this.keyDialog.itemId = item.id;
    },

    updateTenantAppKey() {
      const self = this;
      let expDate = null;
      // TODO: how can we clear exp date?

      // if(self.keyDialog.data.expDate){
      //   expDate = new Date(self.keyDialog.data.expDate).getFullYear();
      //   expDate += '-';
      //   let m = new Date(self.keyDialog.data.expDate).getMonth() + 1;
      //   expDate += (m < 10)? `0${m}` : m;
      //   expDate += '-';
      //   let d = new Date(self.keyDialog.data.expDate).getDate();
      //   expDate += (d < 10)? `0${d}` : d;
      // }

      const apiOptions = {
        url: `/consoleapi/environments/${self.envSelected.value}/tenants/${self.keyDialog.itemId}/key`,
        method: "patch",
        params: {
          key: {
            // expDate: expDate,
            expDate: self.keyDialog.data.expDate,
            new: self.keyDialog.data.new
          }
        }
      };

      this.keyDialog.trigger = false;
      self.getSendData(apiOptions).then(() => {
        self.pushMessage({
          type: 'success',
          title: `Application Updated`,
          text: `Application Key has been updated.`
        });
        setTimeout(() => {
          self.listItems();
        }, 2000);
      });
    },

    //security update
    showAppSecurity(item) {
      if (this.grid.fieldAccess.security) {
        this.securityDialog.width = 800;
        this.securityDialog.title = 'Network & Device Security for ' + item.code;
        this.securityDialog.data = {...item.security};
        this.securityDialog.trigger = true;
        this.securityDialog.itemId = item.id;

        if (!this.securityDialog.data.geo) {
          this.securityDialog.data.geo = {};
        }

        if (!this.securityDialog.data.geo.allow) {
          this.securityDialog.data.geo.allow = [];
        }

        if (!this.securityDialog.data.geo.deny) {
          this.securityDialog.data.geo.deny = [];
        }

        if (!this.securityDialog.data.device) {
          this.securityDialog.data.device = {};
        }

        if (!this.securityDialog.data.device.allow) {
          this.securityDialog.data.device.allow = [];
        }

        if (!this.securityDialog.data.device.deny) {
          this.securityDialog.data.device.deny = [];
        }
      }
    },

    updateAppSecurity() {
      const self = this;
      delete self.securityDialog.data.geo.search;
      let payload = {...self.securityDialog.data};
      payload.geo = {
        allow: self.securityDialog.data.geo.allow,
        deny: self.securityDialog.data.geo.deny
      };

      this.$nextTick(() => {
        const apiOptions = {
          url: `/consoleapi/environments/${self.envSelected.value}/tenants/${self.securityDialog.itemId}/security`,
          method: "patch",
          params: {
            security: payload
          }
        };
        this.securityDialog.trigger = false;
        self.getSendData(apiOptions).then(() => {
          self.pushMessage({
            type: 'success',
            title: `Application Updated`,
            text: `Application Security has been updated.`
          });
          setTimeout(() => {
            self.listItems();
          }, 2000);
        });
      })
    },

    //social
    async showSocial(item) {
      this.socialDialog.loading = true;
      this.socialDialog.width = 800;
      this.socialDialog.title = 'Login 3rd Party Platform Settings for ' + item.code;
      this.socialDialog.data = {...item.login};
      this.socialDialog.trigger = true;
      this.socialDialog.itemId = item.id;

      //assert client if oauth2
      if (this.socialDialog.data.type === 'oauth2' && !this.socialDialog.data.client) {
        this.socialDialog.data.client = {
          id: '',
          secret: ''
        };
      }

      //assert if ldap
      if (this.socialDialog.data.type === 'ldap') {

        if (!this.socialDialog.data.client) {
          this.socialDialog.data.client = {
            url: [],
            timeout: 0,
            connectTimeout: 0,
            reconnect: false
          };
        }

        if (!this.socialDialog.data.user) {
          this.socialDialog.data.user = {
            key: '',
            fields: {
              firstName: '',
              lastName: '',
              email: ''
            }
          };
        }
      }

      //assert the arw field
      if (!this.socialDialog.data.register) {
        this.socialDialog.data.register = null;
      }

      const apiOptions = {
        noLoading: true,
        url: `/consoleapi/environments/${this.envSelected.value}/arw/custom-entries`,
        method: "get",
      };

      let list = await this.getSendData(apiOptions);
      this.socialDialog.arwList = [];
      if (list && list.items) {
        this.socialDialog.arwList = list.items.map((i) => {
          return i.name;
        });
      }

      this.socialDialog.loading = false;
    },

    updateSocialSettings() {
      const self = this;
      let payload = {...self.socialDialog.data};
      if (payload.type === 'ldap') {
        payload.client.url = payload.client.url.split(",");
      }

      const apiOptions = {
        url: `/consoleapi/environments/${self.envSelected.value}/tenants/${self.socialDialog.itemId}/social`,
        method: "patch",
        params: {
          login: payload
        }
      };

      this.socialDialog.trigger = false;
      self.getSendData(apiOptions).then(() => {
        self.pushMessage({
          type: 'success',
          title: `Application Updated`,
          text: `Application Login 3rd Party settings have been updated.`
        });
        setTimeout(() => {
          self.listItems();
        }, 2000);
      });
    }
  }
}

</script>

<style scoped lang="scss">
.routerLink {
  text-decoration: none;
}

.card-outter {
  position: relative;
  padding-bottom: 30px;
}

.card-actions {
  border-top: solid 1px #eee;
  width: 100%;
  position: absolute;
  bottom: 0;
}

</style>
