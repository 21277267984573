<template>
  <v-container
      id="tenants"
      fluid
      align="center"
      tag="section"
  >

    <v-row no-gutters>
      <v-col cols="12" md="12">
        <base-material-card
            color="success"
            icon="mdi-apps"
            inline
            title="Applications"
        >
          <v-tabs right>
            <v-tab>
              <v-icon class="mr-2">mdi-apps</v-icon>
              General
              <help-circle :context="{bottom: true, link: 'tenants', text: 'Multitenant Applications'}"></help-circle>
            </v-tab>
            <v-tab>
              <v-icon class="mr-2">mdi-account-network</v-icon>
              Social
              <help-circle :context="{bottom: true, link: 'socialPlatforms', text: 'Multitenant Social Platforms'}"></help-circle>
            </v-tab>

            <v-tab-item><tenants-apps :environments="environments" :env-selected="envSelected" :tenantType="'general'"></tenants-apps></v-tab-item>
            <v-tab-item><tenants-apps :environments="environments" :env-selected="envSelected" :tenantType="'social'"></tenants-apps></v-tab-item>
          </v-tabs>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>

import TenantsApps  from "@/views/internal/pages/tenants/Tenants";
import globalMixins from "@/mixins/globalMixins";
import HelpCircle   from "@/views/internal/components/custom/helpCircle";

export default {
  name: 'tenants',

  mixins: [globalMixins],

  components: {
    HelpCircle,
    TenantsApps
  },
  props: {
    environments: {
      type: Array
    },
    envSelected: {
      type: Object
    }
  }
};
</script>